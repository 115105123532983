import React                   from 'react';
import Breakpoint              from 'react-socks';
import { Container, Col, Row } from 'react-grid-system';
import styled                  from '@emotion/styled';
import { graphql }             from 'gatsby';

import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Boxgroup          from '@interness/theme-twentynineteen/src/components/Boxgroup/Boxgroup';
import Display           from '@interness/theme-twentynineteen/src/components/Display/Display';
import CTASection        from '@interness/web-core/src/components/modules/CTASection/CTASection';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Mounted           from '@interness/web-core/src/components/structure/Mounted/Mounted';

const List = styled.ul`
  margin-left: 0;
  li {
    margin: 0;
  }
`;

export const displayOverrides = {
  watches: `
      <p>Eine gute Armbanduhr ist ein treuer Begleiter, der weit mehr ist als ein reiner Funktionsgegenstand. Ob Handaufzug oder Automatik, sind sie kleine feinmechanische Wunderwerke. Ein feines Sortiment an Uhren mit Quarzwerken finden Sie ebenfalls bei uns.</p> 
      <p>Ob zeitlos oder topmodern, bei uns finden Sie den richtigen Begleiter für Ihr Handgelenk.</p> 
    `,
  wedding_rings: `
      <p>Außergewöhnliches Design aus der eigenen Trauring-Manufaktur verleiht Ihren Traumringen ein Hauch von Ewigkeit. Unser Team der Goldschmiede fertig Ihre Eheringe ganz individuell, kreativ und mit höchster Perfektion.</p>
      <p>Ihre Trauringe sollen auch in vielen Jahren noch den Moment verkörpern, als Sie sich das „Ja“ geben haben. Lassen Sie sich bei einem Besuch inspirieren und finden Sie Ihre Traumringe.</p>
    `,
  jewelry: `
      <p>Die Welt des Schmucks erstrahlt durch glänzende Materialen wie Gold, Platin und Silber. In Verbindung mit ausgewählten Edelsteinen werden daraus bezaubernde Kleinode.  Bei uns erleben Sie die Faszination von strahlenden Brillanten in edlen Metallen „hautnah“.</p> 
      <p>Selbstverständlich finden Sie bei uns auch stylischen, topmodernen uns angesagten Schmuck als perfektes Accessoire zu jedem Ihrer Outfits.</p>   
    `,
}

const IndexPage = (props) => {

  const displayInserts = [{
    title: 'Atelier',
    description: `
        <p>Mit der Liebe zur Goldschmiedekunst, viel Erfahrung im traditionellen Handwerk mit bewährten Techniken und das stete Bestreben Außergewöhnliches zu schaffen, erfüllen wir Ihre Wünsche in unserem Atelier. </p>
        <p>Meisterliche Ausführung von individuellen Schmuckanfertigungen, Umarbeitungen und Reparaturen sind unsere Expertise.</p>
        <p>Besonders, einzigartig und stilsicher sind die Kleinode unseres Ateliers.</p>`,
    image: props.data.directusMediaCollection.media[1].file.localFile,
    link: '/atelier'
  }]

  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Mounted>
        <Breakpoint large up>
          <Boxgroup floating={false}/>
          <Spacer/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Dassmann</Heading>
          <Spacer height={20}/>
          <span style={{ textAlign: 'justify' }}>
            <p>Seien Sie herzlich willkommen bei Juwelier Dassmann in Coesfeld, im Herzen des Münsterlandes.</p>
            <p>Auf traditionelle Weise tragen wir seit mehr als 50 Jahren in Coesfeld bei Münster dazu bei, Ihren Wünschen und Träumen bei Uhren, Schmuck und Trauringen und Eheringen Gestalt zu geben. 
              Ein hoher Qualitätsanspruch, große Leidenschaft und das stete Bestreben Außergewöhnliches zu schaffen, prägen unser Handeln, und setzen die Tradition fort, 
              mit der Uhrmachermeister Herbert Dassmann vor mehr als 50 Jahren hier in Coesfeld begann. Seit dem Jahr 2005 führen Erika und Gregor Dassmann das Juweliergeschäft in zweiter Generation 
              ganz im Sinne des im Jahr 2010 verstorbenen Gründers auf höchstem Niveau fort.</p>
            <MoreExpander title='weiterlesen'>
              <p>Juwelier Dassmann steht für kompetente Beratung, vertrauensvolle Kundennähe, ausgezeichneten Service und ein sehr gutes Gespür für aktuelle Trends bei Uhren und Schmuck. 
              Ihre Lieblingsstücke sind bei unserem Goldschmiedemeister und Uhrmachermeister bestens aufgehoben. Hier bieten wir Ihnen perfekten Service und Reparaturen in unserer Meisterwerkstatt.
              Die besonderen Leistungen unserer Meister Uhrmacherhandwerks und der Goldschmiedekunst sind weit über die Grenzen Coesfelds hinaus bekannt. In unserer Trauring Manufaktur erschaffen wir
               individuelle die Symbole für die Liebe und den immerwährenden Zusammenhalt. Handwerkliche Perfektion, edle Materialien und feinste Brillanten sind die Grundlagen für Trauringe von Juwelier Dassmann. 
               Passend zu unseren Traumringen erstellen wir erstklassige Gravuren in unserer CNC Werkstatt mit eigenem Laser.
              Gerne begrüßen wir Sie in unserem modernen Geschäft in der Kupferstraße 1 + 3 in Coesfeld. Neben einem liebevoll ausgewählten Sortiment von erlesenem Schmuck und feinen Uhren zeigen wir 
              Ihnen in unserem Trauringstudio eine Auswahl traumhafter Trauringe und Eheringe. Hier bieten wir Ihnen Entspannung und Entschleunigung, während wir uns in einem persönlichen Gespräch Zeit für Sie und Ihre Wünsche nehmen.<br/>
              Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.
              </p>

              <p>Bis bald in Coesfeld<br/>Ihre Familie Dassmann</p>
              </MoreExpander>
          </span>
        </section>
      </Wrapper>
      <section>
        <Spacer height={20}/>
        <Heading tag={'h2'}>Begleiten Sie uns durch die Welt von Juwelier Dassmann</Heading>
        <Spacer height={20}/>
        <Display inserts={displayInserts} buttonPrimary={true} layout='square'
                 order={['engagement_rings', 'watches', 'jewelry']} exclude={['accessories']}
                 textOverride={displayOverrides}/>
        <Spacer height={20}/>
      </section>
      <Wrapper>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>
            Professioneller Service gehört seit jeher zu den wesentlichen Tugenden unseres Hauses. Wir reparieren Ihre
            Armbanduhren, Ihre Großuhren, führen Goldschmiedereparaturen aus und kaufen Ihr Altgold an.
          </p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h2>Armbanduhren</h2>
                  <List>
                    <li>mechanische Uhren / Handaufzug</li>
                    <li>Quarzarmbanduhren</li>
                    <li>Automatikuhren</li>
                    <li>Batteriewechsel</li>
                    <li>Komplette Revisionen</li>
                    <li>Taschenuhren</li>
                    <li>Glasersatz</li>
                    <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                    <li>Bandanpassung</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h2>Goldschmiedereparaturen</h2>
                  <List>
                    <li>Goldschmiedereparaturen aller Art</li>
                    <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                    <li>Ringgrößen ändern</li>
                    <li>Perlenketten neu aufziehen oder knoten</li>
                    <li>Trauringumarbeitung</li>
                    <li>Schmuck reinigen und aufarbeiten</li>
                    <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h2>Großuhren</h2>
                  <List>
                    <li>Heim – und Tischuhren, Jahresuhren</li>
                    <li>Standuhren</li>
                    <li>Regulatoren</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h2>Altgold- und Altsilberankauf</h2>
                  <List>
                    <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an:</li>
                    <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken und Münzen</li>
                    <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen</li>
                    <li>Altgoldankauf ist Vertrauenssache</li>
                  </List>
                </Col>
              </Row>
            </Container>
          </MoreExpander>
        </section>
        <Spacer height={20}/>
      </Wrapper>
      <CTASection/>
    </>
  )
};

export default IndexPage;

export const query = graphql`
  query {
    directusMediaCollection(name: {eq: "atelier-headers"}) {
      media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 700, maxHeight: 700, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
